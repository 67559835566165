// @flow
import React, {type ElementConfig, type Node, PureComponent} from 'react';
import classnames from 'classnames';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'none',
    color: [theme.palette.common.black, '!important'],
    borderBottomWidth: theme.spacing(0.5),
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    transition: theme.transitions.create('border-bottom-color', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.ease,
    }),
    '&:hover, &$active': {
      borderBottomColor: colors.java1,
    },
  },
  active: {},
});

type Props = {
  active?: boolean,
  children: Node,
  ...ElementConfig<typeof Typography>,
  // injected
  classes: Classes<typeof styles>,
};

// can't be a function component since they don't take
// ref's, and next Links pass refs to wrapped <a>'s
class DesktopLink extends PureComponent<Props> {
  render() {
    const {active, children, classes, ...props} = this.props;

    return (
      <Typography
        className={classnames(classes.root, {
          [classes.active]: active,
        })}
        component="a"
        {...props}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(DesktopLink);
