// @flow
import React, {type ElementConfig, PureComponent} from 'react';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';
import Logo from 'logo';

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2.5),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(3),
    },
  },
  logo: {
    height: '100%',
  },
});

type Props = {
  ...ElementConfig<'a'>,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

// can't be a function component since they don't take
// ref's, and next Links pass refs to wrapped <a>'s
class HeaderLogo extends PureComponent<Props> {
  render() {
    const {className, classes, ...props} = this.props;

    return (
      //$ExpectError - this doesn't work - ElementConfig is inexact, so we can't make Props exact
      <a className={classnames(className, classes.root)} {...props}>
        <Logo className={classes.logo} />
      </a>
    );
  }
}

export default withStyles(styles)(HeaderLogo);
