// @flow
import React, {type Node} from 'react';
import classnames from 'classnames';

import {dimensions} from 'theme/v2';
import {withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    overflowX: 'hidden',
    minHeight: '100vh',
    minWidth: dimensions.minScreenWidth,
    maxWidth: 1700,
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[24],
  },
});

type Props = {
  children: Node,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

function Page({children, className, classes}: Props) {
  return <div className={classnames(className, classes.root)}>{children}</div>;
}

export default withStyles(styles)(Page);
