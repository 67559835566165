// @flow
import React, {type Node} from 'react';
import {withStyles} from 'ui';
import classnames from 'classnames';

import {type ClassesFromFn} from 'types';
import {dimensions} from 'theme/v2';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    flex: '1 1 auto',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: dimensions.outerPadding.xs,
      paddingRight: dimensions.outerPadding.xs,
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: dimensions.outerPadding.sm,
      paddingRight: dimensions.outerPadding.sm,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: dimensions.outerPadding.mdUp,
      paddingRight: dimensions.outerPadding.mdUp,
    },
    '&:not($fullscreen)': {
      [theme.breakpoints.only('xs')]: {
        maxWidth: dimensions.maxContentWidth + dimensions.outerPadding.xs * 2,
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: dimensions.maxContentWidth + dimensions.outerPadding.sm * 2,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: dimensions.maxContentWidth + dimensions.outerPadding.mdUp * 2,
      },
    },
  },
  fullscreen: {},
});

type Props = {|
  children: Node,
  fullscreen?: boolean,
  className?: string,
  contentClassName?: string,
  style?: {},
  // injected
  classes: ClassesFromFn<typeof styles>,
|};

function FullWidthContent({
  classes,
  className,
  contentClassName,
  fullscreen,
  children,
  style,
}: Props) {
  return (
    <div className={classnames(classes.root, className)}>
      <div
        className={classnames(classes.content, contentClassName, {
          [classes.fullscreen]: fullscreen,
        })}
        style={style}>
        {children}
      </div>
    </div>
  );
}

export default withStyles(styles)(FullWidthContent);
