// @flow
import React from 'react';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    width: 1,
    backgroundColor: colors.grey5,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

function DesktopLinksDivider({classes}: Props) {
  return <div className={classes.root} />;
}

export default withStyles(styles)(DesktopLinksDivider);
