// @flow
import React from 'react';

import {withStyles, type Theme, type Classes} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    width: theme.spacing(6),
    height: 1,
    backgroundColor: colors.grey4,
    marginBottom: theme.spacing(3.5),
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

function MobileMenuDivider({classes}: Props) {
  return <div className={classes.root} />;
}

export default withStyles(styles)(MobileMenuDivider);
