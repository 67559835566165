// @flow
import React, {type Node} from 'react';

import {withStyles, type Theme, type Classes} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

type Props = {
  children: Node,
  // injected
  classes: Classes<typeof styles>,
};

function MobileMenu({children, classes}: Props) {
  return <div className={classes.root}>{children}</div>;
}

export default withStyles(styles)(MobileMenu);
