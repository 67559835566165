// @flow
import React, {Fragment, PureComponent, type Node} from 'react';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';
import {dimensions, createShadow} from 'theme/v2';
import {FullWidthContent} from 'layout';
import MobileMenuButton from './MobileMenuButton';
import {HEADER_BANNER_HEIGHT} from './HeaderBanner';

const styles = (theme: Theme) => ({
  spacer: {
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.default,
      easing: theme.transitions.easing.ease,
    }),
    '&:not($showBanner)': {
      [theme.breakpoints.down('sm')]: {
        height: dimensions.headerHeight.smDown,
      },
      [theme.breakpoints.up('md')]: {
        height: dimensions.headerHeight.mdUp,
      },
    },
    '&$showBanner': {
      [theme.breakpoints.down('sm')]: {
        height: dimensions.headerHeight.smDown + HEADER_BANNER_HEIGHT,
      },
      [theme.breakpoints.up('md')]: {
        height: dimensions.headerHeight.mdUp + HEADER_BANNER_HEIGHT,
      },
    },
  },
  root: {
    pointerEvents: 'none',
    zIndex: 1,
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    minWidth: dimensions.minScreenWidth,
    maxWidth: dimensions.maxScreenWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& > *:not($mobileMenuContainer)': {
      pointerEvents: 'all',
    },
  },
  header: {
    zIndex: 1,
    flex: '0 0 auto',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.default,
      easing: theme.transitions.easing.ease,
    }),
    '&:not($showShadow)': {
      boxShadow: 'none',
    },
    '&$showShadow': {
      boxShadow: createShadow(
        [0, 1, 4, -1, 0.025],
        [0, 4, 5, 0, 0.015],
        [0, 1, 10, 0, 0.015],
      ),
    },
    '&:not($showBanner)': {
      transform: 'translateY(0px)',
    },
    '&$showBanner': {
      transform: `translateY(${HEADER_BANNER_HEIGHT}px)`,
    },
  },
  showShadow: {},
  showBanner: {},
  mobileMenuContainer: {
    position: 'relative',
    flex: '1 1 auto',
    overflowY: 'auto',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.ease,
    }),
    '&:not($isMobileMenuOpen)': {
      pointerEvents: 'none',
      opacity: 0,
      transform: `scale3d(1.075, 1.075, 1.075) translate3d(0px, ${theme.spacing(
        3,
      )}px, 0px)`,
    },
    '&$isMobileMenuOpen': {
      pointerEvents: 'all',
      opacity: 1,
      transform: 'scale3d(1, 1, 1) translate3d(0px, 0px, 0px)',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  isMobileMenuOpen: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: dimensions.headerHeight.smDown,
    },
    [theme.breakpoints.up('md')]: {
      height: dimensions.headerHeight.mdUp,
    },
  },
  left: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  right: {
    flex: '0 0 auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
});

type Props = {
  left?: Node,
  right?: Node,
  mobileMenu?: Node,
  banner?: Node,
  showBanner?: boolean,
  fullscreen?: boolean,
  shadow?: boolean,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

type State = {
  scrollY: number,
  isMobileMenuOpen: boolean,
};

class Header extends PureComponent<Props, State> {
  state = {
    scrollY: 0,
    isMobileMenuOpen: false,
    isBannerShowing: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleViewportChangeDebounced);
    window.addEventListener('resize', this.handleViewportChangeDebounced);
    document.addEventListener('keydown', this.handleKeyDown);
    this.handleViewportChange();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleViewportChangeDebounced);
    window.removeEventListener('resize', this.handleViewportChangeDebounced);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleViewportChange = () => {
    this.setState({scrollY: window.pageYOffset});
  };

  handleViewportChangeDebounced = debounce(this.handleViewportChange, 200, {
    leading: true,
  });

  handleKeyDown = (e: KeyboardEvent) => {
    const {isMobileMenuOpen} = this.state;

    if (e.keyCode === 27 && isMobileMenuOpen) {
      // esc key pressed
      this.setState({isMobileMenuOpen: false});
    }
  };

  handleMobileMenuToggle = () => {
    this.setState(state => ({isMobileMenuOpen: !state.isMobileMenuOpen}));
  };

  handleMobileMenuClick = (e: SyntheticMouseEvent<>) => {
    switch (e.target.constructor) {
      case HTMLAnchorElement:
      case HTMLButtonElement:
        this.setState({isMobileMenuOpen: false});
        break;
      default:
        // noop
        break;
    }
  };

  render() {
    const {
      left,
      right,
      mobileMenu,
      fullscreen,
      banner,
      showBanner,
      shadow,
      className,
      classes,
    } = this.props;
    const {scrollY, isMobileMenuOpen} = this.state;

    return (
      <Fragment>
        <div className={classnames(className, classes.root)}>
          {banner}
          <FullWidthContent
            className={classnames(classes.header, {
              [classes.showShadow]:
                shadow || scrollY > 0 || isMobileMenuOpen || showBanner,
              [classes.showBanner]: showBanner,
            })}
            contentClassName={classes.content}
            fullscreen={fullscreen}>
            <div className={classes.left}>
              {mobileMenu && (
                <MobileMenuButton
                  isOpen={isMobileMenuOpen}
                  onToggle={this.handleMobileMenuToggle}
                />
              )}
              {left}
            </div>
            <div className={classes.right}>{right}</div>
          </FullWidthContent>
          {mobileMenu && (
            <div
              onClick={this.handleMobileMenuClick}
              className={classnames(classes.mobileMenuContainer, {
                [classes.isMobileMenuOpen]: isMobileMenuOpen,
              })}>
              {mobileMenu}
            </div>
          )}
        </div>
        <div
          className={classnames(classes.spacer, {
            [classes.showBanner]: showBanner,
          })}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Header);
