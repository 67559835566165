// @flow
import React, {Fragment, type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Theme, type Classes} from 'ui';
import {dimensions} from 'theme/v2';
import {HEADER_BANNER_HEIGHT} from 'header';

export const SIDEBAR_WIDTH = 300;

const styles = (theme: Theme) => ({
  sidebar: {
    position: 'fixed',
    width: SIDEBAR_WIDTH,
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.default,
      easing: theme.transitions.easing.ease,
    }),
    '&$showBanner': {
      height: `calc(100% - ${dimensions.headerHeight.mdUp +
        HEADER_BANNER_HEIGHT}px)`,
    },
    '&:not($showBanner)': {
      height: `calc(100% - ${dimensions.headerHeight.mdUp}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      marginLeft: SIDEBAR_WIDTH,
    },
  },
  showBanner: {},
});

// BYO header, render as a sibling above this component
type Props = {
  // content inside sidebar, hidden on mobile
  sidebar?: Node,
  // main content
  children?: Node,
  // slides content down to make room for banner
  showBanner?: boolean,
  // injected
  classes: Classes<typeof styles>,
};

function SidebarLayout({sidebar, children, showBanner, classes}: Props) {
  return (
    <Fragment>
      <div
        className={classnames(classes.sidebar, {
          [classes.showBanner]: showBanner,
        })}>
        {sidebar}
      </div>
      <div
        className={classnames(classes.content, {
          [classes.showBanner]: showBanner,
        })}>
        {children}
      </div>
    </Fragment>
  );
}

export default withStyles(styles)(SidebarLayout);
