// @flow
import React from 'react';
import classnames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import {IconButton, withStyles, type Theme, type Classes} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: ['transparent', '!important'],
    },
  },
  icon: {
    color: theme.palette.common.black,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.ease,
    }),
    position: 'absolute',
    '&$show': {
      opacity: 1,
      transform: 'rotate(0deg)',
    },
    '&:not($show)': {
      opacity: 0,
    },
  },
  iconOpen: {
    '&:not($show)': {
      transform: 'rotate(45deg)',
    },
  },
  iconClose: {
    '&:not($show)': {
      transform: 'rotate(-45deg)',
    },
  },
  show: {},
});

type Props = {
  isOpen: boolean,
  onToggle(): void,
  // injected
  classes: Classes<typeof styles>,
};

function MobileMenuButton({classes, isOpen, onToggle}: Props) {
  return (
    <IconButton className={classes.root} onClick={onToggle} disableRipple>
      <MenuIcon
        fontSize="small"
        className={classnames(classes.icon, classes.iconOpen, {
          [classes.show]: !isOpen,
        })}
      />
      <CloseIcon
        fontSize="small"
        className={classnames(classes.icon, classes.iconClose, {
          [classes.show]: isOpen,
        })}
      />
    </IconButton>
  );
}

export default withStyles(styles)(MobileMenuButton);
