// @flow
import React, {type Node, type ElementConfig, PureComponent} from 'react';
import classnames from 'classnames';

import {Typography, withStyles, type Theme, type Classes} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    fontSize: '18px',
    textDecoration: 'none',
    marginBottom: theme.spacing(3.5),
    transition: theme.transitions.create('color'),
    fontWeight: 500,
    '&:not($active):not(:hover)': {
      color: [colors.grey2, '!important'],
    },
    '&$active, &:hover': {
      color: [colors.grey1, '!important'],
    },
    '&$active': {
      fontWeight: 600,
    },
  },
  active: {},
});

type Props = {
  children: Node,
  active?: boolean,
  className?: string,
  ...ElementConfig<typeof Typography>,
  // injected
  classes: Classes<typeof styles>,
};

// can't be a function component since they don't take
// ref's, and next Links pass refs to wrapped <a>'s
class MobileMenuLink extends PureComponent<Props> {
  render() {
    const {active, className, children, classes, ...props} = this.props;
    return (
      <Typography
        className={classnames(className, classes.root, {
          [classes.active]: active,
        })}
        component="a"
        {...props}>
        {children}
      </Typography>
    );
  }
}
export default withStyles(styles)(MobileMenuLink);
