// @flow
import React, {type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Theme, type Classes} from 'ui';
import {colors, dimensions} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: colors.grey7,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: dimensions.outerPadding.xs,
      paddingRight: dimensions.outerPadding.xs,
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: dimensions.outerPadding.sm,
      paddingRight: dimensions.outerPadding.sm,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: dimensions.outerPadding.mdUp,
      paddingRight: dimensions.outerPadding.mdUp,
    },
  },
});

type Props = {
  children: Node,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

function Sidebar({children, className, classes}: Props) {
  return <div className={classnames(classes.root, className)}>{children}</div>;
}

export default withStyles(styles)(Sidebar);
