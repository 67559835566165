//@flow
import React, {type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Theme, type Classes} from 'ui';
import {dimensions, spacing} from 'theme/v2';

export const HEADER_BANNER_HEIGHT = spacing.unit * 6;

const styles = (theme: Theme) => ({
  root: {
    position: 'absolute',
    maxWidth: dimensions.maxScreenWidth,
    top: 0,
    height: HEADER_BANNER_HEIGHT,
    width: '100%',
    display: 'flex',
    zIndex: 1,
  },
});

type Props = {|
  children: Node,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
|};

function HeaderBanner({className, classes, children}: Props) {
  return <div className={classnames(classes.root, className)}>{children}</div>;
}

export default withStyles(styles)(HeaderBanner);
