// @flow
import React, {type Node} from 'react';
import classnames from 'classnames';

import {withStyles, type Theme, type Classes} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

type Props = {
  children: Node,
  className?: string,
  // injected
  classes: Classes<typeof styles>,
};

function SidebarNav({children, className, classes}: Props) {
  return <div className={classnames(classes.root, className)}>{children}</div>;
}

export default withStyles(styles)(SidebarNav);
