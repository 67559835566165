// @flow
import React, {type Node} from 'react';

import {withStyles, type Classes, type Theme} from 'ui';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

type Props = {
  children: Node,
  // injected
  classes: Classes<typeof styles>,
};

function DesktopLinks({children, classes}: Props) {
  return <div className={classes.root}>{children}</div>;
}

export default withStyles(styles)(DesktopLinks);
