// @flow
import React, {type Node, type ComponentType} from 'react';
import classnames from 'classnames';

import {Typography, withStyles, type Theme, type Classes} from 'ui';
import {colors} from 'theme/v2';

const styles = (theme: Theme) => ({
  root: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: 'solid',
    textDecoration: 'none',
    transition: theme.transitions.create('border-left-color', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.ease,
    }),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    '&:not($active):not(:hover)': {
      borderLeftColor: 'transparent',
    },
    '&$active, &:hover': {
      borderLeftColor: 'transparent',
    },
    '&:hover $icon:not($active)': {
      color: colors.bossanova1,
    },
    '&:hover $text:not($active)': {
      color: colors.bossanova1,
    },
  },
  icon: {
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.ease,
    }),
    '&:not($active)': {
      color: colors.grey5,
    },
    '&$active': {
      color: colors.bossanova1,
    },
  },
  text: {
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.ease,
    }),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:not($active)': {
      color: colors.grey3,
    },
    '&$active': {
      color: colors.bossanova1,
      fontWeight: 600,
    },
  },
  active: {},
});

type Props = {|
  children: string,
  active?: boolean,
  className?: string,
  icon?: Node,
  component?: ComponentType<*>,
  typographyComponent?: ComponentType<*>,
  // injected
  classes: Classes<typeof styles>,
|};

function SidebarNavItem({
  children,
  icon,
  active,
  className,
  component,
  typographyComponent,
  classes,
  ...props
}: Props) {
  // needs to be capitalized
  const Component = component || 'a';
  const TypographyComponent = typographyComponent || 'p';

  return (
    <Component
      className={classnames(className, classes.root, {
        [classes.active]: active,
      })}
      {...props}>
      {icon && (
        <div
          className={classnames(classes.icon, {
            [classes.active]: active,
          })}>
          {icon}
        </div>
      )}
      <Typography
        component={TypographyComponent}
        className={classnames(classes.text, {
          [classes.active]: active,
        })}>
        {children}
      </Typography>
    </Component>
  );
}

export default withStyles(styles)(SidebarNavItem);
